import React from "react"
import {withPrefix} from "gatsby"
// import { Helmet } from "react-helmet"
// import { Link } from "gatsby"

import "../../../../styles/core.css"
import "../../../../styles/styles.css"
import Layout from "../../../../components/Layout"
import MenuOne from "../../../../components/MenuOne"
import MenuTwo from "../../../../components/MenuTwo"
import MenuThree from "../../../../components/MenuThree"
import Content from "../../../../components/Content"
import Gallery from "../../../../components/Gallery"
import MenuFour from "../../../../components/MenuFour"

const galImgs = ['/page-images/anchor_watch_apartments.jpg']

export default function OurWork() {
  return (
    <Layout breadcrumbs={["Dunallen Apartments, Donaghadee"]}>
      <Gallery items={
      galImgs.map(image => ({
          original: withPrefix(image),
          thumbnail: withPrefix(image),
        })
      )}
    />

<Content>
<p>This scheme comprises twelve new-build apartments together with a private basement car park. This project is on the site of the old Dunallen Hotel on Shore Street, Donagadee and commands excellent views of the Copeland Islands. Light floods into a three-storey glazed atrium to the rear of the building and provides natural daylight to the communal stairwell </p>
</Content>

      <div class="region region-content-bottom">
        <MenuOne active='our-work' />
        <MenuTwo
          active=""
          items={[
            '/our-work/residential',
            '/our-work/commercial',
            '/our-work/industrial',
            '/our-work/leisure',
            '/our-work/community',
            '/our-work/retail',
          ]} />
        <MenuThree
          items={[
            '/our-work/residential/houses',
            '/our-work/residential/extensions',
            '/our-work/residential/apartments',
            '/our-work/residential/developments',
          ]} />
        <MenuFour
          items={[
            '/our-work/residential/apartments/dunallen-apartments-donaghadee',
            '/our-work/residential/apartments/hillsborough-old-road-apartments',
          ]}
        />
      </div>

  </Layout>

  )
}
